@import 'variables';

* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

body,
html,
#root {
    height: 100%;
    width: 100%;
}

#root {
    font-size: $font-size-default;
    font-family: $default-font;
    background-color: #f7faff;
    color: $color-info_dark;
}

@keyframes alertAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.import-config-alert {
    animation: alertAnimation 2000ms linear 1000ms forwards;
}
