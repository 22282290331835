$default-font: 'Source Sans Pro', sans-serif;

// Common
$color-white: #ffffff;
$color-black: #000000;
$color-gray-light: #f4f4f4;
$color-gray: #808080;
$color-gray-dark: #444444;
$color-gray-darkest: #1b1b1b;

$color-primary: #3d70f4;
$color-primary_dark: #2752bf;

$color-secondary: #682db3;
$color-secondary_dark: #49138d;

$color-success: #2bbf5e;
$color-success_dark: #118f3c;

$color-warning: #f5a623;

$color-danger: #ff0000;
$color-danger_dark: #b61212;

$color-info_light: #d7d7d7;
$color-info: #7b8a92;
$color-info_dark: #303030;

// Indents
$indent-small: 5px;
$indent-semismall: 10px;
$indent-default: 15px;
$indent-semilarge: 20px;
$indent-large: 25px;
$indent-extralarge: 30px;

// Fonts
$font-weight-light: 300;
$font-weight-default: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-extrasmall: 8px;
$font-size-small: 10px;
$font-size-semismall: 12px;
$font-size-default: 14px;
$font-size-medium: 16px;
$font-size-semilarge: 18px;
$font-size-large: 20px;
$font-size-extralarge: 22px;

// Forms
$field-primary: #e1e2e7;
$field-secondary: #9b9b9b;
$bg-field: #f7faff;
$focus-border-color: #50e3c2;

//Shadows
$box-shadow-primary: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
